import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BankAccount, BankAccountType, BankProvider, BankAccountResponse } from '../models/bankAccount';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BankAccountService {

  private API_URL = `${environment.backendURL}/bank-accounts`;

  constructor(private http: HttpClient) { }


  getBankAccounts() {
    const params = new HttpParams()
      .set('search', '%')
      .set('owner', '')
      .set('page', '1')
      .set('pageSize', '999999999')
    return this.http.get<BankAccountResponse>(`${this.API_URL}`, { params });
  }

  getBankAccountsType() {
    let bankAccountType: BankAccountType[] = []
    bankAccountType.push({ type: '', description: 'Select' })
    bankAccountType.push({ type: 'Withdraw', description: 'Withdraw (บัญชีถอน)' })
    bankAccountType.push({ type: 'Deposit', description: 'Deposit (บัญชีรับโอน)' })
    bankAccountType.push({ type: 'Saving', description: 'Saving (บัญชีบริษัท)' })
    bankAccountType.push({ type: 'Profit', description: 'Profit (บัญชีนอก)' })
    bankAccountType.push({ type: 'Partner', description: 'Partner (บัญชีพาร์ทเนอร์)' })
    bankAccountType.push({ type: 'Other', description: 'Other' })
    return bankAccountType;
  }

  getBankProvider() {
    let bankProvider: BankProvider[] = []
    bankProvider.push({ symbol: '', name: 'เลือก', nameEn: "Select" })
    bankProvider.push({ symbol: 'SCB', name: 'ธนาคารไทยพาณิชย์', nameEn: "The Siam Commercial Bank (SCB)" })
    bankProvider.push({ symbol: 'KBNK', name: 'ธนาคารกสิกรไทย (KBNK)', nameEn: "Kasikorn Bank (KBNK)" })
    bankProvider.push({ symbol: 'KTBA', name: 'ธนาคารกรุงไทย (KTBA)', nameEn: "Krungthai Bank (KTBA)" })
    bankProvider.push({ symbol: 'BBLA', name: 'ธนาคารกรุงเทพ (BBLA)', nameEn: "Bangkok Bank (BBLA)" })
    bankProvider.push({ symbol: 'BAYA', name: 'ธนาคารกรุงศรีอยุธยา (BAYA)', nameEn: "Bank of Ayudhya (BAYA)" })
    bankProvider.push({ symbol: 'GSBA', name: 'ธนาคารออมสิน (GSBA)', nameEn: "Government Savings Bank (GSBA)" })
    bankProvider.push({ symbol: 'TTB', name: 'ธนาคารทีเอ็มบีธนชาต (TTB)', nameEn: "TMBThanachart (TTB)" })
    bankProvider.push({ symbol: 'KKBA', name: 'ธนาคารเกียรตินาคิน (KKBA)', nameEn: "Kiatnakin Bank (KKBA)" })
    bankProvider.push({ symbol: 'GHBA', name: 'ธนาคารอาคารสงเคราะห์ (GHBA)', nameEn: "Government Housing Bank (GHBA)" })
    bankProvider.push({ symbol: 'BAAC', name: 'ธนาคารเพื่อการเกษตรและสหกรณ์ (BAAC)', nameEn: "Bank for Agriculture and Agriculture Cooperatives (BAAC)" })
    bankProvider.push({ symbol: 'CIMB', name: 'ธนาคารซีไอเอ็มบี (CIMB)', nameEn: "CIMB (CIMB)" })
    bankProvider.push({ symbol: 'UOB', name: 'ธนาคารยูโอบี (UOB)', nameEn: "United Overseas Bank (Thai) PCL" })


    return bankProvider;
  }

  createBankAccount(bankAccount: BankAccount) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization' : 'Bearer '+localStorage.getItem('token') })
    let options = { headers: headers };
    return this.http.post<any>(`${this.API_URL}`, bankAccount, options);
  }

  searchBankAccount(search: string, owner: string, accType: string): Observable<BankAccountResponse> {
    const params = new HttpParams()
      .set('search', search)
      .set('owner', owner)
      .set('accType', accType)
      .set('page', '1')
      .set('pageSize', '10000')
    return this.http.get<BankAccountResponse>(`${this.API_URL}`, { params });
  }

  updateBankAccount(bankAccount: BankAccount) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization' : 'Bearer '+localStorage.getItem('token') })
    let options = { headers: headers };
    return this.http.post<any>(`${this.API_URL}/update`, bankAccount, options);
  }

  getBankAccountByType(accountType: string): Observable<BankAccount[]> {
    const params = new HttpParams().set('accountType', accountType);
    return this.http.get<BankAccount[]>(`${this.API_URL}/active/type`, { params });
  }



}
