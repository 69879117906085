import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ViewEncapsulation } from '@angular/core';

import { formatDate } from '@angular/common';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { PaymentCallback } from '../models/payment';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import QRCode from 'qrcode';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";
import { observable } from 'rxjs';
import { PaymentService } from '../services/payment.service';
import { DFUserBank } from '../models/customer.interface';
declare var generatePayload: any;

@Component({
  selector: 'app-df-qr-modal',
  templateUrl: './df-qr-modal.component.html',
  styleUrls: ['./df-qr-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DfQrModalComponent implements OnInit, AfterViewInit {

  @ViewChild('qrcode')
  qrcodeRef: ElementRef;

  @ViewChild('alert')
  alertRef: NgbModal;

  timecoldown: number = 165;
  modalTime: number = 5;

  timeLeft: number = this.timecoldown;
  timeDisplay: string;
  interval: any;
  countPaymentChecking: number = 50;
  headers = new HttpHeaders();
  isDisabled: boolean;
  paymentStatus: string;
  payment: PaymentCallback;

  currentDate: any;

  nowDate: Date;

  path = `${environment.backendURL}`

  jwt: string = ''
  selectedAccountID: any;

  uid: string
  amt: number
  txn: string

  noAccount: boolean
  error: string = ''


  constructor(
    private _modalService: NgbModal,
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private router: Router) {
  }

  ngOnInit() {
    this.headers = this.headers.set('Content-Type', 'application/json');
    this.isDisabled = true;

    this.jwt = this.route.snapshot.paramMap.get("jwt")
    this.selectedAccountID = this.route.snapshot.paramMap.get("selected")

    this.currentDate = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en');
    this.nowDate = new Date();

  }

  ngAfterViewInit() {
    this._modalService.open(this.alertRef, { keyboard: false, size: 'xl', backdrop: 'static' });

    this.paymentService.getVerifiedAccount(this.jwt).subscribe(bnk => {
      if (!bnk) {
        this.router.navigate([`/user/register/${this.jwt}`]);
        this._modalService.dismissAll()
      } else {
        this.getPayment().then(res => {
          this.payment = res;
          this.generateQRCode(res);
          this.startTimer();
          this._modalService.open(this.alertRef, { keyboard: false, size: 'xl', backdrop: 'static' });
          this.isDisabled = false;
        }, (err: HttpErrorResponse) => {
          if (err.error === 'Service not available') {
            this.error = "ขณะนี้ระบบอยู่ในช่วงปรับปรุง กรุณาลองใหม่อีกครั้งในภายหลัง";
            clearInterval(this.interval);
            this.spinner.hide();
          } else {
            if (err.status === 406) {
              this.error = err.error;
            } else {
              this.error = "ตรวจพบข้อผิดพลาดกรุณาทำรายการใหม่จากหน้า Dafabet อีกครั้ง"
            }
            clearInterval(this.interval);
            this.spinner.hide();
          }
        });
      }
    })



  }

  closeModal(flag: boolean) {
    this._modalService.dismissAll();
    if (!flag) {
      clearInterval(this.interval);
      this.router.navigate([`/status/false/${this.payment.id}/${this.jwt}`]);
      // this.router.navigate([`/support/${this.payment.id}`]);
    }
  }

  generateQRCode(payment: PaymentCallback) {
    const x = generatePayload(payment.promptpay, { amount: payment.amount });
    const canvas = this.qrcodeRef.nativeElement;
    QRCode.toCanvas(
      canvas,
      x,
      {
        errorCorrectionLevel: 'H',
        color: { dark: '#000000', light: '#FFFFFF' },
        width: 320,
      }

    ).then(() => {
      const logo = new Image();
      const context = canvas.getContext('2d');
      logo.onload = () => {
        context.drawImage(logo, (canvas.width - logo.width) / 2, (canvas.height - logo.height) / 2);
      };
      logo.src = `../assets/images/small-logo.jpg`;
    });
  }



  getStatementStatus() {
    return this.httpClient.get<PaymentCallback>(`${this.path}/payment/check/${this.payment.id}`, { headers: this.headers }).toPromise()
  }

  getPaymentTransaction() {
    return this.httpClient.get<any>(`${this.path}/payment/check-transaction/${this.payment.id}`, { headers: this.headers }).toPromise()
  }

  getPayment() {
    return this.httpClient.post<PaymentCallback>(`${this.path}/payment`, { data: this.jwt, selectedAccountID: ~~this.selectedAccountID }, { headers: this.headers }).toPromise()
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.modalTime === 0) {
        this.closeModal(true);
      } else {
        this.modalTime--;
      }
      if (this.timeLeft > 0) {
        this.timeLeft--;
        if (this.timeLeft % 10 == 0) {
          this.countPaymentChecking -= 1;
          this.onPayment()
        }
      }

      let doingDate = new Date()
      let diffSec = (doingDate.getTime() - this.nowDate.getTime()) / 1000
      let sec = this.timecoldown - ~~(Math.abs(diffSec))
      if (sec < 0) {
        clearInterval(this.interval);
        this.callback(false);
      }
      this.timeDisplay = `${(Math.floor(sec / 60))} นาที ${(Math.floor(sec % 60))} วินาที`
    }, 1000)
  }

  callback(status) {
    if (status) {
      this.router.navigate([`/status/true/${this.payment.id}/${this.jwt}`]);
    } else {
      this.router.navigate([`/status/false/${this.payment.id}/${this.jwt}`]);
      // this.router.navigate([`/support/${this.payment.id}`]);
    }
  }

  onPayment() {
    this.getStatementStatus().then(statementResult => {
      if (statementResult.isSuccessed) {
        clearInterval(this.interval);
        this.spinner.hide();
        this.callback(true)

      }

      if (this.countPaymentChecking == 0) {
        clearInterval(this.interval);
        this.spinner.hide()
        this.callback(false)
      }
    });
  }

  close() {
    clearInterval(this.interval);
  }

}
