import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Support } from '../models/support';
import { SupportService } from '../services/support.service';
import { BankAccountService } from '../services/bank-account.service';
import { BankProvider } from '../models/bankAccount';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../manage/guards/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DFUserBank } from 'src/app/models/customer.interface';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-df-issue-modal',
  templateUrl: './df-issue-modal.component.html',
  styleUrls: ['./df-issue-modal.component.css']
})

export class DfIssueModalComponent implements OnInit, AfterViewInit {

  bankProvider$: BankProvider[] = []
  f: FormGroup;
  slip: any;
  txn: number;
  flag: boolean;
  fixText: string;
  alerts: any = '';
  // reason = '';
  name = '';
  surname = '';
  username = '';

  isDup: any;

  verifyStmt: Support
  onVerifySuccess: boolean;

  jwt: string = ''

  constructor(private bankAccountService: BankAccountService,
    private storage: AngularFireStorage,
    private supportService: SupportService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public authService: AuthService,
    private paymentService: PaymentService) { }



  ngOnInit(): void {
    // txn is df_statement_id
    let pTxn = this.route.snapshot.paramMap.get("txn")
    if (pTxn) {
      this.supportService.checkDuplicateSupportByDfTxnId(pTxn).subscribe(dup => {
        this.isDup = dup
      }, error => {
      })
      this.flag = true;
      this.txn = Number(pTxn)
      this.name = ''
      this.surname = ''
      this.jwt = this.route.snapshot.paramMap.get("jwt")

      this.paymentService.getDfUserDetailRoute(this.jwt).subscribe(dfUserResult => {
        this.username = dfUserResult.username;
      })

    } else {
      this.flag = false;
      this.txn = null;
      this.name = this.authService.getStorageByname('name') == '' ? '' : `${this.authService.getStorageByname('name')[0].toLocaleUpperCase()}${this.authService.getStorageByname('name').substring(1)}`
      this.surname = this.authService.getStorageByname('surname') == '' ? '' : `${this.authService.getStorageByname('surname')[0].toLocaleUpperCase()}${this.authService.getStorageByname('surname').substring(1)}`
      this.username = this.authService.getStorageByname('username') == '' ? '' : `${this.authService.getStorageByname('username')[0].toLocaleUpperCase()}${this.authService.getStorageByname('username').substring(1)}`
    }

    this.f = new FormGroup({
      bankName: new FormControl('', Validators.required),
      sDate: new FormControl({ year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }),
      time: new FormControl({ hour: 0, minute: 0, second: 0 }),
      accountNo: new FormControl('', [Validators.required, Validators.minLength(10), Validators.pattern('[0-9]*')]),
      // accountName: new FormControl(`${this.name} ${this.surname}`, Validators.pattern('[a-zA-Z ]*')),
      name: new FormControl(this.name, [Validators.required, Validators.pattern('[a-zA-Z -]*')]),
      lastname: new FormControl(this.surname, [Validators.required, Validators.pattern('[a-zA-Z -]*')]),
      amount: new FormControl('', Validators.required),
      slip: new FormControl(''),
      username: new FormControl(this.username, Validators.required),
      // reason: new FormControl('เนื่องจากท่านไม่ได้โอนเงินตามบัญชีที่เลือกไว้', Validators.required),
    });
    this.bankProvider$ = this.bankAccountService.getBankProvider();
    this.spinner.hide();

  }

  detectLanguage(input: string) {
    let regexString = new RegExp('^[a-zA-Z\\- ]+$');
    let text = this.f.get(input).value
    if (regexString.test(text)) {
      this.f.get(input).setValue(text);
      this.fixText = text;
    } else {
      if (text == '') {
        this.f.get(input).setValue('');
        this.fixText = ''
      } else {
        this.f.get(input).setValue(this.fixText);
      }
    }
  }

  ngAfterViewInit() {
  }

  storeFile(event: any) {
    this.slip = event.target.files[0];
  }

  onSubmit() {

    this.spinner.show()
    this.alerts = ''

    if (!this.slip) {
      this.alerts = { type: 'danger', message: 'กรุณาแนบเอกสาร' }
      this.spinner.hide()
      return;
    }

    let file = this.slip
    let filePath = `/upload/support/${new Date().getTime()}.${file.name.split('.')[file.name.split('.').length - 1]}`;
    let fileRef = this.storage.ref(filePath);
    let task = this.storage.upload(filePath, file);
    let date = new Date(this.f.controls['sDate'].value.year,
      this.f.controls['sDate'].value.month - 1,
      this.f.controls['sDate'].value.day,
      this.f.controls['time'].value.hour,
      this.f.controls['time'].value.minute,
      0, 0)
    let support = new Support(this.f.value)
    support.accountName = `${support.name} ${support.lastname}`
    support.transactionDate = date
    support.status = 'processing'
    support.product = 'TBC'
    support.createdAt = new Date()
    support.updatedAt = new Date()
    support.dfTransactionId = this.txn == null || this.txn == 0 ? null : this.txn;
    support.txn_name = this.name;
    support.txn_lastname = this.surname;
    support.username = this.username;
    // support.reason = support.reason === 'etc' ? this.reason : support.reason;

    this.supportService.checkDuplicateRoute(support).subscribe(dup => {
      if (dup) {
        this.alerts = { type: 'danger', message: 'รายการนี้ท่านได้แจ้งเรียบร้อยแล้ว' }
        this.spinner.hide()
        return;
      }
      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(url => {
            support.slip = url
            support.dfTransactionId = this.txn == null || this.txn == 0 ? null : this.txn;
            this.supportService.createSupport(support).subscribe(res => {
              this.alerts = { type: 'success', message: 'ส่งข้อมูลให้เจ้าหน้าที่เรียบร้อยแล้ว' }
              this.spinner.hide()

            }, err => {
              this.spinner.hide()
              this.alerts = { type: 'danger', message: 'เกิดข้อผิดพลาดกรุณาติดต่อเจ้าหน้าที่' }
            })
          })
        })
      ).subscribe()
    }, error => {
      if (error.error == "Duplicate") {
        this.alerts = { type: 'danger', message: 'รายการนี้ท่านได้แจ้งเรียบร้อยแล้ว' }
      } else {
        this.alerts = { type: 'danger', message: 'ไม่พบรายการที่ท่านแจ้ง' }
      }
      this.spinner.hide()
    })

  }


  close(alert: any) {
    if (alert.type == 'success') {
      return;
    }
    this.alerts = '';
  }
}
