// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backendURL: 'https://api.xopay.cash',
  //backendURL: 'http://localhost:1323',
  callbackURL: '#',
  googleURL : 'https://api.xopay.cash',
  //googleURL: 'http://localhost:1323',
  firebaseConfig: {
    apiKey: "AIzaSyDyX4O_YW30yaVnvtA4Ug0FV0lDPpSkOFc",
    authDomain: "sopay-a454a.firebaseapp.com",
    projectId: "sopay-a454a",
    storageBucket: "sopay-a454a.appspot.com",
    messagingSenderId: "551255121800",
    appId: "1:551255121800:web:a4214f99105bf01e74e43d"
  }

  
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
